function setCustomPassword(){
    webix.editors.customPassword = webix.extend({
        render:function(){
          const node = webix.html.create("div", {
            "class":"webix_dt_editor custom-pass"
          }, 
          `<input type="password" aria-label="${this.config.label || ""}" 
            title="Password requirements" 
            data-container="body" 
            data-toggle="popover" 
            data-html="true" 
            data-trigger="focus" 
            data-placement="bottom" 
            data-content="At least 1 lowercase letter<br/>At least 1 uppercase letter<br/>At least 1 numerical character<br/>At least 6 characters">
          <span class="webix_icon wxi-eye"></span>`);
      
          const icon = node.querySelector(".webix_icon");
          // show password on icon hover
          webix.event(icon, "mouseover", e => {
            this.getInputNode().type = "text"
          });
          webix.event(icon, "mouseleave", e => {
            this.getInputNode().type = "password"
          });
          
          return node;
        },
        afterRender:function(){
            $('[data-toggle="popover"]').popover();   
        }
      }, webix.editors.text)
}

module.exports = {setCustomPassword};