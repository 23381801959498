import {JetView, plugins} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const Device = require('../../models/device');
const i18n = require('../../locales/device_en');
const moment = require('moment');

export default class OverviewView extends JetView {
    config()
    {
        var detailsProperty = 
          {
            view:"property",  
            id:"details_property",
            scroll:"y",
            nameWidth:200,
            elements:[],
            on:{
              'onBeforeEditStart':function(id){                
                  return false;
              }
            }
          };

        return {
            id:'app',
            cols:[{
              rows:[                
                detailsProperty,
                { view:"button", value:"Refresh", click:() => this.refreshData()}
                ]
              }        
              ]
        }
    }

    init(view){
      this.user = this.app.getService("user").getUser();
      this.serialNumber = webix.storage.session.get('serialNumber');
      this.refreshData();            
    }   

    refreshData(){
      ProgressHelper.showProgress($$("app"));
      Device.details(this.serialNumber).then(function(response)
      {
        var result = response.json();

        if(result.status==='ok')
        {       
          var device = result.device; 
          var elements = [];
          var index = 0;
          elements[index++] = { label:'Device (' + device.serialNumber + ') is in ' + (device.isOnline===true ? 'online':'offline') + ' state' , type:"label"};
          elements[index++] = { label:"Read date", type:"text", value: moment.utc(device.createdAt).local().format('L LT')};
          
          //Refresh status indicator in top
          var status = $$("top:status");
          status.define("icon",device.isOnline===true? "mdi mdi-wifi":"mdi mdi-wifi-strength-off-outline")
          status.refresh();          

          for (const [key, value] of Object.entries(device.details)) 
          { 
            var elementLabel = key;          
            if(i18n.hasOwnProperty(key)){
              elementLabel = i18n[key];
            }

            elements[index++] = { label:elementLabel, type:"text", value:value};
          }

          var details_property = $$("details_property");
          details_property.define("elements",elements)
          details_property.refresh();
        }
        else
        {
          webix.message({
            text: result.message,
            type:"error"
        });
        }

        ProgressHelper.hideProgress($$("app")); 
      });    
    }
}