import {JetView, plugins} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const ProfileModel = require('../../models/profile');
const UserModel = require('../../models/user');
const PropertySheetHelper = require('../../controls/property_sheet/helper');

export default class FormView extends JetView {
    config()
    {
       var user = this.app.getService("user").getUser();

        var main_current = {
            view:"property",  id:"main_property",maxHeight:142,nameWidth:200,
            elements:[
              { label:"Profil", type:"label" },
              { label:"Username", type:"text", id:"userName", value: user.name},
              { label:"Old password", type:"password", id:"password"},
              { label:"New password", type:"customPassword", id:"new_password"},
              { label:"Repeate new password", type:"customPassword", id:"confirm_password"} 
            ],
            on:{
              'onBeforeEditStart':function(id){   
                  if(id==='userName'){             
                    return false;
                  }
              }
            }
          };  
          
        var main_company = {
            view:"property",  id:"main_company",maxHeight:58,nameWidth:200,
            elements:[
              { label:"Company", type:"label" },
              { label:"Company", type:"text", id:"company", value: ''} 
            ]
          }; 
          
        var main_api_token = {
            view:"property",  id:"main_api_token",maxHeight:58,nameWidth:200,
            elements:[
              { label:"API", type:"label" },
              { label:"Token", type:"text", id:"token", value: ''} 
            ],
            on:{
              'onBeforeEditStart':function(id){ 
                    return false;                  
              }
            }
          }; 

        return {
            id:'app',
            rows:[
                main_current,
                { view:"button", value:"Change password", click:() => this.changePassword()},             
                {view:"label"},
                main_company,
                { view:"button", value:"Save company", click:() => this.setCompany()},
                {view:"label"},
                main_api_token,
                { view:"button", value:"New token", click:() => this.newApiToken()},
                {}
              ]
        }
    }   
    
    init(){
      this.registerPropertySheetType();
      //Read back user data
      UserModel.getUser().then(function(response)
          {
              var result = response.json();
              if(result !== null && result.status == 'ok')
              {
                $$("main_company").setValues({
                  company: result.user.company? result.user.company : ''
                });

                $$("main_api_token").setValues({
                  token: result.user.token ? result.user.token : ''
                });
              }
          });
    }

    registerPropertySheetType(){
      let propertySheet = $$("main_property");

      propertySheet.registerType("password",{
        template: PropertySheetHelper.hideData,
        editor:"password"
      });

      propertySheet.registerType("customPassword",{
        template: PropertySheetHelper.hideData,
        editor:"customPassword"
      });
    }

    setCompany(){
      const view = this.$$("main_company");
      view.editStop();
      const data = view.getValues();
      
      ProgressHelper.showProgress($$("app"));
      ProfileModel.setUserCompany(data.company).then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  webix.message({
                    text: 'Company data changed successfully!',
                    type:"info"});                  
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
        }

        newApiToken(){
          var parent = this;              
          webix.confirm({title:'New API Token', text:`Are you sure you want to create a new token, the previous token will be deleted?`})
          .then(function(result,config){	
            parent.generateApiToken();
          });
        }
    
    generateApiToken(){
      var user = this.app.getService("user").getUser();

      ProgressHelper.showProgress($$("app"));
      ProfileModel.createUserApiToken(user.name).then(function(response)
       {
       ProgressHelper.hideProgress($$("app"));
   
         var result = response.json();
         if(result !== null)
         {
            if(result.status != 'ok'){
              webix.message({
                text: result.message,
                type:"error"
              });
            }
            else{
              $$("main_api_token").setValues({
                token: result.token ? result.token : ''
              });
            }
         }
         else{
           webix.message({
             text:"Unexpected error! Please try again.",
             type:"error"
           });
         }
       });
    }

    changePassword(){
      const view = this.$$("main_property");
      view.editStop();
      const data = view.getValues();

      if(!data.password || !data.password.trim()){
        webix.message({
            text:"Current password is empty!",
            type:"error"
        });
        return;
      }

      if(!data.new_password || !data.new_password.trim()){
        webix.message({
            text:"New password is empty!",
            type:"error"
        });
        return;
      }

      if(!data.confirm_password || !data.confirm_password.trim()){
        webix.message({
            text:"Confirm password is empty!",
            type:"error"
        });
        return;
      }

      if(data.new_password !== data.confirm_password){
        webix.message({
            text:"Confirm password and New password is not equal!",
            type:"error"
        });
        return;
      }

      if(data.password === data.new_password){
        webix.message({
            text:"Current password and New password can not equal!",
            type:"error"
        });
        return;
      }
      
      ProgressHelper.showProgress($$("app"));
      ProfileModel.setUserPassword(data.password,data.new_password,data.confirm_password).then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  webix.message({
                    text: 'Password changed successfully!',
                    type:"info"});

                  $$("password_property").setValues({
                    password: '', 
                    new_password: '',
                    confirm_password:''
                 });
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
    }    
}