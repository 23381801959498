import "../../styles/tokenmanager.css";
import {JetView, plugins} from "webix-jet";
import TokenManagereEditor from "./tokenmanager_editor";
const ProgressHelper = require('../../helpers/progress');
const Helper = require('../../helpers/deviceToken');
const DeviceToken = require('../../models/devicetoken');
const moment = require('moment');

var tokenManagereEditor;
var tokensDatatable;

export default class TokenManagerView extends JetView {
    config()
    {		
		var serialNumber = webix.storage.session.get('serialNumber');

        var datatable = {
			id:"tokensDatatable",
			view:"datatable", 
			columns:[
				{ id:"isOnline",    header:"Status",template:this.formatStatus},
				{ id:"token", width:220,  header:"Token"},
				{ id:"tag1", width:220,  header:"TAG1"},
				{ id:"tag2", width:220,   header:"TAG2"},
				{ id:"email", width:220,  header:"Email"},
				{ id:"expiration",   header:"Expiration",template:this.formatExpiration},				
				{ id:"rx",	header:"Rx",template:this.formatRx},
				{ id:"tx",  header:"Tx",template:this.formatTx},
				{ id:"lastLogin", width:150,   header:"LastLogin",template:this.formatDate},
				{ header:"Edit", width:55, css:"icon", template: `<span class='webix_icon wxi-dots'/>`},
				{ header:"Delete", width:55, css:"icon", template: `<span class='webix_icon wxi-trash'/>`},
				{ header:"Send", width:55, css:"icon", template: `<span class='webix_icon wxi-sync'/>`},
				{ header:"Expire", width:55, css:"icon", template: `<span class='webix_icon mdi mdi-alarm-note-off'/>`}
			],
			url:`api/devicetoken/tokens?serialNumber=${serialNumber}`,				
			save:{	
				"insert":`api/devicetoken/insert?serialNumber=${serialNumber}`,
				"update":`api/devicetoken/update?serialNumber=${serialNumber}`,
				"delete":`api/devicetoken/delete?serialNumber=${serialNumber}`
			},		 
			onClick:{
				"wxi-dots":this.editEdit, 
				"wxi-trash":this.editDelete,
				"wxi-plus":this.editNew,
				"wxi-sync":this.editSync,
				"mdi-alarm-note-off":this.editExpire,				
			  }
		};

        return {
			id:'app',			
            cols:[
				{
              rows:[		
				datatable,
				{ view:"button", value:"New", click:() => this.editNew() }
				]
			  }
			],
        }
	}	

    init(view){
		tokenManagereEditor = this.ui(TokenManagereEditor);
		tokensDatatable = $$("tokensDatatable");

		//Not working the base initialization if insert,update,delete url used. Only url works correctly
		var dataProcessor = webix.dp(tokensDatatable);
		dataProcessor.config.updateFromResponse = true;
		dataProcessor.attachEvent('onAfterSaveError', function(id, status, response, details){			
			//Delete new row if error occured
			if(status=='insert'){			
				tokensDatatable.remove(id);
				webix.message({
					text:"Creating new token failed!",
					type:"error"
				});
			}
		});
	}   

	formatStatus(obj){
		var result = 'Offline';
		if(obj.isOnline){
			result = 'Online';
		}
		return result;
	}

	formatRx(obj){		
		return (obj.rx ? obj.rx:0)+' MB';
	}

	formatTx(obj){
		return (obj.tx ? obj.tx:0)+' MB';
	}

	formatExpiration(obj){
		var result = Helper.convertTokenExpirationToString(obj.expiration);

		return result;
	}

	formatDate(obj){
		var result = '';
		if(obj.lastLogin){
			result = moment.utc(obj.lastLogin).local().format('L LT');
		}
		return result;
	}
	

    refreshData(){
		tokensDatatable.load("api/devicetoken/tokens", "json");
	}

	editEdit(ev, id) {		
		tokenManagereEditor.showWindow(tokensDatatable,id);	  
	}	

	editDelete(ev, id)
	{  
		var table = this;
		var row = this.getItem(id);

		webix.confirm(`Delete the ${row.token} token?`)
		.then(function(result,config){			
			table.remove(id);
		});
	}

	editExpire(ev, id)
	{  
		tokensDatatable.updateItem(id, { expiration: moment.utc().add(-1,'days').startOf('day').toJSON() });
	}
	  
	editNew()
	{  
		tokenManagereEditor.showWindow(tokensDatatable,null);
	}

	editSync(ev, id)
	{  
		const data = tokensDatatable.getItem(id);
		if(data){

		ProgressHelper.showProgress($$("app"));
		DeviceToken.sync(data.serialNumber,data.token).then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'success'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  webix.message({
                    text: 'Token information has been sent out!',
                    type:"info"});
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
		}
	}
}
