import "../../styles/userdevice.css";
import "../../styles/common.css";
import {JetView, plugins} from "webix-jet";
import UserDeviceAddEditor from "./userdevice_add";
const ProgressHelper = require('../../helpers/progress');
const moment = require('moment');

export default class UserDeviceView extends JetView {
    config()
    {
		var user = this.app.getService("user").getUser();

        var datatable = {
			id:"devicesDatatable",
			view:"datatable",
			columns:[
                { id:"selected",    header:"Selected", template:"{common.radio()}"},
                { id:"readDate",  width:200,header:[{text:"Date"}],template:this.formatDate, sort:"moment"},
				{ id:"serialNumber",  width:150, header:[{text:"SerialNumber", content:"excelFilter", mode:"text"}], sort:"string"},			
                { id:"isOnline",    header:[{text:"Status", content:"excelFilter", mode:"number"}],template:this.formatStatus, sort:"boolean"},
                { id:"signal",  width:75, css:"icon",template:this.formatSignal, header:"Signal"},
				{ id:"description",  width:200, header:[{text:"Description", content:"excelFilter", mode:"text"}], sort:"string"},
				{ id:"gps", header:"GPS", width:200, template:this.formatGps},				
				{ id:"delete", header:"Delete", width:55, css:"icon", template: `<span class='webix_icon wxi-trash'/>`}
			],
			url:`api/userdevice/devices?email=${user.name}`,				
			save:{	
				"insert":`api/userdevice/insert?email=${user.name}`,
				"delete":`api/userdevice/delete?email=${user.name}`
			},		 
			on: {
				"onCheck":this.changeDevice,
				"onAfterLoad":this.afterLoad,
				"onItemClick":this.itemClick
			},
			onClick:{
				"wxi-trash":this.editDelete			
			},
			hover:"userdevicehover"
		};

        return {
			id:'app',			
            cols:[
				{
              rows:[	
				datatable,								
				{ view:"button", value:"New", click:() => this.editNew() }
				]
			  }
			],
        }
	}	

    init(view){
		ProgressHelper.showProgress($$("app")); 
		this.userDeviceAddEditor = this.ui(UserDeviceAddEditor);
	}   

	formatStatus(obj){
		var result = 'Offline';
		if(obj.isOnline){
			result = 'Online';
		}
		return result;
	}

    formatDate(obj){
		var result = '';
		if(obj.readDate){
			result = moment.utc(obj.readDate).local().format('L LT');
		}
		return result;
	}

	formatGps(obj){
		if(obj.gps){
			return `<a href='https://www.google.com/maps/search/?api=1&query=${obj.gps}' target='_blank'>${obj.gps}</a>`;
		}
		return '';
	}

	formatSignal(obj){
		if(obj.signal){			
			var numbers = obj.signal.match(/\d+/g).map(Number);
			if(numbers.length > 0){
				switch(numbers[0]){
					case 0:
						return `<span class='mdi mdi-wifi-strength-off-outline'/>`;
					case 1:
					case 2:
					case 3:
						return `<span class='mdi mdi-wifi-strength-${numbers[0]}'/>`;
					default:
						return `<span class='mdi mdi-wifi-strength-4'/>`;
				}
			}
			return `<span class='mdi mdi-wifi-strength-off-outline'/>`;
		}
		return  `<span class='mdi mdi-wifi-strength-off-outline'/>`;
	}

	editDelete(ev, id)
	{  
		var table = this;
		var row = this.getItem(id);

		webix.confirm(`Delete the ${row.serialNumber} device?`)
		.then(function(result,config){			
			table.remove(id);
			if(row.selected===true){
				table.$scope.deviceSelected(null);
			}
		});
	}

	itemClick(id, e, trg){
		if(id.column=='gps' || id.column=='delete'){
			return;
		}

		//Check if need to select
		var record = this.getItem(id.row);
		this.$scope.deviceSelected(record)
	}

	afterLoad(){
		var serialNumber = webix.storage.session.get('serialNumber');

		this.eachRow(function(row){ 
			const record = this.getItem(row);
			
			if(record.isOnline===true){		
				this.addRowCss(row,'online');
			}			
			
			if(record.serialNumber===serialNumber){			
				record.selected = true;		
				this.addRowCss(row,'selected');	
				this.updateItem(row, record);
			}
		});

		ProgressHelper.hideProgress($$("app")); 
	}
	  
	editNew()
	{  
		this.userDeviceAddEditor.showWindow(this.refreshData);	 
	}

	changeDevice(row,col,state){
		var rowData = this.getItem(row);
		this.$scope.deviceSelected(rowData)
	}

	refreshData(){
		var table = $$("devicesDatatable");
		if(table)
		{
			ProgressHelper.showProgress($$("app")); 
			table.clearAll();
			table.load(table.config.url);
		}
	}

	deviceSelected(row){

		if(row && (row.selected===true)){
			//already selected
			return;
		}

		webix.storage.session.put('serialNumber', row ? row.serialNumber:'');
		
		var sideMenu = $$("left:sidebar");
		var selectedItem = sideMenu.getSelectedItem();
		
		var headerName = $$("top:headerName");
		headerName.define("label",`Widiin - ${selectedItem.value} - ${row ? row.serialNumber : ''}`)
		headerName.refresh();

		this.refreshData();
		//Refresh sidebar
		$$("left:sidebar").$scope.refresh();	
		$$("left:sidebar").select('pages.userdevice');
		//Refresh top
		$$("top:toolbar").$scope.refresh();	
	}	
}
