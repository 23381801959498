import {JetView, plugins} from "webix-jet";

export default class LeftView extends JetView{
	config(){   
    
    var items = this.createMenuItems();

		var ui = {
      id:"left:sidebar",
      view: "sidebar",
      css:"webix_dark",
      collapsed:true,
      data: items,
      on:{
        onAfterSelect: function(id)
        {
          var serialNumber = webix.storage.session.get('serialNumber');

          var link = "/index/" + id;
          this.$scope.show(link);
          var selectedItem = this.getSelectedItem();
          var headerName = $$("top:headerName");
          headerName.define("label",`Widiin - ${selectedItem.value} - ${serialNumber}`)
          headerName.refresh();
        }
      }
    };

		return ui;
    }

    init(){
      this.user = this.app.getService("user").getUser()
    }

    refresh(){
      var sidebar = $$("left:sidebar");
      sidebar.clearAll();
      var newData = this.createMenuItems()
      sidebar.parse(newData);
      sidebar.refresh();      
    }
    
    createMenuItems(){
      var user = this.app.getService("user").getUser();
      var serialNumber = webix.storage.session.get('serialNumber');
  
      var items = [];
      if(user.isUser){
        items.push({ value:"Devices", id:"pages.userdevice", icon:"mdi mdi-cellphone-wireless" });
        items.push({ value:"Tokens", id:"pages.my_tokens", icon:"mdi mdi-cellphone-key" });
      }
  
      if(serialNumber && serialNumber.length > 0) {
        items.push( { value:"Overview", id:"pages.overview", icon:"mdi mdi-view-dashboard" });
        items.push({ value:"Token Manager",	id:"pages.tokenmanager",  icon:"mdi mdi-puzzle" });
        
        if(user.isUser){
          items.push({ value:"Device Settings",	id:"profile.profile_device",  icon:"mdi mdi-cog" });
        }
      }
      items.push( { value:"Help", id:"pages.widiin_help", icon:"mdi mdi-help" });
      items.push( { value:"Version", id:"pages.version", icon:"mdi mdi-information-variant" });
      return items;
    }
}

