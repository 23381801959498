import {JetView, plugins} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const UserDevice = require('../../models/userdevice');

var device_editor;
var device_editor_form;
var parentRefrechCallback;

export default class UserDeviceAddView extends JetView {
    config()
    {
        return {
			view:"popup",
			id: "editor",
			position:"center",
			body:{
			  view:"form", 
			  id: "editor-form",
			  elements:[
				//labels for non-editable fields
				{label: "Add new device", view:"label", align:"center"},				
				{label: "SerialNumber",labelWidth:100, view:"text", width:300, name:"serialNumber"},
				{label: "Password",view:"text",type:"password",labelWidth:100, width:300, name:"password"},
				{
				  cols: [
					{
					  view:"button", value:"Cancel", click: function() {
						device_editor_form.clear();
						device_editor.hide();
					  }
					},
					{
					  view:"button", value:"Add", css: "webix_primary", click: this.editEnd
					}
				  ]
				}
			  ],
			}
		  }
	}

	init(view){
		device_editor = $$("editor");
		device_editor_form = $$("editor-form");		
	}
	
	showWindow(refreshCallback){		
		ProgressHelper.hideProgress($$("editor")); 
		device_editor_form.clear();
		this.getRoot().show();
		parentRefrechCallback = refreshCallback;
	}

	editEnd() {
		const vals = device_editor_form.getValues();

		//New item and email is empty or Update item and email is empty
		if(vals.serialNumber.length == 0)
		{
			webix.message({
				text:"Serial number is empty!",
				type:"error"
			});
			return;
		}

		if(vals.password.length == 0)
		{
			webix.message({
				text:"Password is empty!",
				type:"error"
			});
			return;
		}		
			
		if(device_editor_form.isDirty()){				
				//Save the device

				var user = this.$scope.app.getService("user").getUser();

				ProgressHelper.showProgress($$("editor"));
				var promise = UserDevice.insert(user.name,vals.serialNumber,vals.password);
				promise.then(function(response)
				{
					var result = response.json();
					if(result !== null)
					{
						if(result.status != 'success'){
								webix.message({
								text: result.message,
								type:"error"
							});
						}
						else {
							//Inform parent to reload							
							device_editor_form.clear();
							device_editor.hide();	

							if(parentRefrechCallback){
								parentRefrechCallback();
							}
						}
					}
					else{
						webix.message({
							text:"Unexpected error! Please try again.",
							type:"error"
						});
					}
				});

				promise.fail(function(){
					ProgressHelper.hideProgress($$("editor")); 
				});

				promise.finally(function(){
					ProgressHelper.hideProgress($$("editor")); 
				});
			}
		}
	}