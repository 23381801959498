import {JetView, plugins} from "webix-jet";
import TopMenu from "./topmenu";
//import { io } from 'socket.io-client';
const device = require('../../models/device');

export default class TopView extends JetView{
	config(){

		var user =this.app.getService("user").getUser();

		var toolbar = { 
			css:"webix_dark",
			view: "toolbar", 
			id:"top:toolbar",
			padding:3, 
			elements: [
				{ view: "icon", icon: "mdi mdi-menu", click: function(){
					$$("left:sidebar").toggle();
				  }
				 },
			{ view: "label", id:"top:headerName", label: this.app.config.name},
			{},
			{ view: "icon", id:"top:status", icon: "mdi mdi-wifi-strength-off-outline"},
			{ view:"button", maxWidth: (user.isUser ? 300 : 175), label: user.name, type:"icon", icon: "mdi mdi-account-outline",click: function(){
				this.$scope.topMenu.showPopup(this.$view);
			}}
		  ]
		  };
		
		var ui = {
			rows:[
				toolbar
			]
		};

		return ui;
	}

	init(){
		this.topMenu = this.ui(TopMenu);
		this.refreshSignal();		
		/*var user = this.app.getService("user").getUser()
		//Init socket with serialNumber
		var port = require('../../../../config_public.json').backend.port;
		this.socket = io(`http://localhost:${port}`,{
			query: {
			  sn: user.name
			}
		  });

		  this.socket.on("status", function(data) 
		  {			
			var serialNumber = webix.storage.session.get('serialNumber');
			//Device is equal with selected
			if(data && data.sn===serialNumber){
				var status = $$("top:status");
				status.define("icon",data.isOnline===true? "mdi mdi-wifi":"mdi mdi-wifi-strength-off-outline")
				status.refresh();
			}
		 });*/
	}

	refresh(){
		this.refreshSignal();
	}

	refreshSignal(){
		var serialNumber = webix.storage.session.get('serialNumber');
		var form = this;
  
		if(serialNumber){
  
		  var promise = device.status(serialNumber);
			  promise.then(function(response)
			  {               
				  var result = response.json();
				  if(result !== null && result.status==='ok')
				  {
					form.changeSignalSign(result.isOnline);
				  }
				  else{
					form.changeSignalSign(false);
				  }
			  });
  
			  promise.fail(function(){
				  form.changeSignalSign(false);
			  });
  
		  //Set timeout then recall refresh
		  webix.delay(function(){
				this.refreshSignal();
			},this,[],10000);          
		}
	  }
	  
	  changeSignalSign(isOnline){
		var status = $$("top:status");
		status.define("icon",isOnline===true? "mdi mdi-wifi-strength-4":"mdi mdi-wifi-strength-off-outline")
		status.refresh();
	  }	  
}