import {JetView, plugins} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const ProfileModel = require('../../models/profile');
const DeviceModel = require('../../models/device');
const validator = require('validator');
const PropertySheetHelper = require('../../controls/property_sheet/helper');

export default class FormView extends JetView {
    config()
    {
      var user = this.app.getService("user").getUser();
      
      var index = 0;      
      var uiRows = [];

        uiRows[index++]  = {
          view:"property",  id:"main_property",maxHeight:170,nameWidth:200,
          elements:[
            { label:"Profil", type:"label" },
            { label:"SerialNumber", type:"text", id:"serialNumber" },
            { label:"Email", type:"text", id:"email"},
            { label:"New password", type:"customPassword", id:"new_password"},
            { label:"Repeate new password", type:"customPassword", id:"confirm_password"},       
            { label:"Status", type:"text", id:"status"}   
          ],
          on:{
            'onBeforeEditStart':function(id){   
                if(id==='serialNumber' || id==='status'){             
                  return false;
                }
            }
          }
        };
        uiRows[index++] = { view:"button", value:"Save", click:() => this.saveData()};
        uiRows[index++] = { view:"label" };

        //Reset index to overwrite controls
        if(user.isUser){
          index = 0;  
        }

        uiRows[index++] = {
          view:"property",  id:"device_property", maxHeight:58,
          elements:[
            { label:"Description", type:"label" },
            { label:"Description", type:"text", id:"description"}    
          ]
        };

        uiRows[index++] = { view:"button", value:"Save description", click:() => this.setDeviceDescription()};
      
        uiRows[index++] = { view:"label" };

        uiRows[index++] = {
            view:"property",  id:"public_key_property", maxHeight:58,
            elements:[
              { label:"Root ssh public key", type:"label" },
              { label:"Key", type:"text", id:"public_key"}    
            ]
          };

        uiRows[index++] = { view:"button", value:"Save public key", click:() => this.changePublicKey()};
        uiRows[index++] = {};

        return {
            id:'app',
            rows:uiRows
          }
    }

    init(view){
      this.registerPropertySheetType();

      ProgressHelper.showProgress($$("app"));

      var serialNumber = webix.storage.session.get('serialNumber');

      ProfileModel.device_status(serialNumber).then(function(response)
          {
              ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  var profile = result.resultProfile;

                 var main_property =  $$("main_property");
                  if(main_property){
                      $$("main_property").setValues({
                        serialNumber: profile.serialNumber ? profile.serialNumber:'', 
                        email: profile.next_email ? profile.next_email:(profile.email ? profile.email:''),
                        status: profile.next_email ? 'Wait of confirm the email address': (profile.email ? 'Email is confirmed':'')
                    });
                  }

                 $$("device_property").setValues({
                  description: profile.description ? profile.description:''
               });

                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
            });               
    }

    
    registerPropertySheetType(){
      let propertySheet = $$("main_property");
      if(propertySheet){
        propertySheet.registerType("customPassword",{
          template: PropertySheetHelper.hideData,
          editor:"customPassword"
        });
      }
    }

    setDeviceDescription(){
      const view = this.$$("device_property");
      view.editStop();
      const data = view.getValues();

      var serialNumber = webix.storage.session.get('serialNumber');
      
      ProgressHelper.showProgress($$("app"));
      DeviceModel.setDeviceDescription(serialNumber,data.description).then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  webix.message({
                    text: 'Description data changed successfully!',
                    type:"info"});                  
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
        }
    
    saveData(){
      const view = this.$$("main_property");
      view.editStop();
      const data = view.getValues();
      var serialNumber = webix.storage.session.get('serialNumber');

      if(!data.email || !data.email.trim()){
        webix.message({
            text:"Email is empty!",
            type:"error"
        });
        return;
      }

      if(data.email && !validator.isEmail(data.email)){
        webix.message({
          text:"Email is not valid!",
          type:"error"
        });
        return;
      }

      if(!data.new_password || !data.new_password.trim()){
        webix.message({
            text:"New password is empty!",
            type:"error"
        });
        return;
      }

      if(!data.confirm_password || !data.confirm_password.trim()){
        webix.message({
            text:"Confirm password is empty!",
            type:"error"
        });
        return;
      }

      if(data.new_password !== data.confirm_password){
        webix.message({
            text:"Confirm password and New password is not equal!",
            type:"error"
        });
        return;
      }
      
      ProgressHelper.showProgress($$("app"));
      var result = ProfileModel.update_device(serialNumber,data.email,data.new_password);
          result.then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  var profile = result.resultProfile;

                  $$("main_property").setValues({
                    serialNumber: profile.serialNumber ? profile.serialNumber:'',
                    email: profile.next_email ? profile.next_email : (profile.email ? profile.email:''),
                    status: profile.next_email?'Wait of confirm the email address':(profile.email ? 'Email is confirmed':'')
                 });
                                    
                  if(profile.next_email){
                    webix.alert({title:"Email sent",text:"Email has been sent to your address ("+profile.next_email+")! Plese check your emails!"});
                  }
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
    }    

    changePublicKey(){
      const view = this.$$("public_key_property");
      view.editStop();
      const data = view.getValues();
      var serialNumber = webix.storage.session.get('serialNumber');

      if(!data.public_key || !data.public_key.trim()){
        webix.message({
            text:"Public key is empty!",
            type:"error"
        });
        return;
      }
      
      ProgressHelper.showProgress($$("app"));
      ProfileModel.setDeviceRootPublicKey(serialNumber,data.public_key).then(function(response)
          {
            ProgressHelper.hideProgress($$("app"));

              var result = response.json();
              if(result !== null)
              {
                if(result.status != 'ok'){
                    webix.message({
                      text: result.message,
                      type:"error"
                  });
                }
                else{
                  webix.message({
                    text: 'Device root ssh public key changed successfully!',
                    type:"info"});

                  $$("public_key_property").setValues({
                    public_key: ''
                 });
                }
              }
              else{
                  webix.message({
                      text:"Unexpected error! Please try again.",
                      type:"error"
                  });
              }
          });
    }
}