import {JetView} from "webix-jet";
const forgotpassword = require('../models/forgotpassword');
const validator = require('validator');

export default class LoginView extends JetView{
    config(){       
        const login_form = {
            view:"form", localId:"login:form",            
            width:webix.env.mobile ? 600 : 400, 
            borderless:false, margin:10,
            rows:[
                { view:"text", name:"login", label:"User Name (email or serial number)", labelPosition:"top"},
                { view:"text", type:"password", name:"pass", label:"Password", labelPosition:"top" },
                { view:"button", value:"Login", click:() => this.do_login(), hotkey:"enter" },
                { view:"button", value:"Forgot Password", click:() => this.do_forgotpassword() },
            ],
            rules:{
                login:webix.rules.isNotEmpty,
                pass:webix.rules.isNotEmpty
            }
        };

        return {
            css:webix.env.mobile ? "login scaleY" : "",
            cols:[{}, {
                localId:"login:top", rows:[
                {}, 
                { type:"header", template: this.app.config.name, css:"webix_dark" },
                login_form,
                {}
            ]}, {}]
        };
    }

    init(view){
        view.$view.querySelector("input").focus();
    }

    do_login(){
        const user = this.app.getService("user");
        const form = this.$$("login:form");
        const ui = this.$$("login:top");

        if (form.validate()){
            const data = form.getValues();
            user.login(data.login, data.pass).catch(function(){
                webix.html.removeCss(ui.$view, "invalid_login");
                form.elements.pass.focus();
                webix.delay(function(){
                    webix.html.addCss(ui.$view, "invalid_login");
                });
            });
        }
    }

    do_forgotpassword(){
        const form = this.$$("login:form");
        const data = form.getValues();
        if(!data.login || !data.login.trim()){
            webix.message({
                text:"User Name is empty!",
                type:"error"
            });
            return;
        }

        if(!validator.isEmail(data.login)){
            webix.message({
                text:"Changing device password is not allowed!",
                type:"error"
            });
            return;
        }
        
        var result = forgotpassword.requesttoken(data.login);
            result.then(function(response)
            {
                var result = response.json();
                if(result !== null)
                {
                    webix.alert({title:"Email sent",text:"Email has been sent to your address ("+result.email+")! Plese check your emails!"});
                }
                else{
                    webix.message({
                        text:"Forgot password failed! Check username validity!",
                        type:"error"
                    });
                }
            });
    }
}