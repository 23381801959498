import {JetView} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const JTM = require('../../models/jtm');
const jtmLinkTemplate = require('../../../../config_public.json').frontend.jtmLinkTemplate;
const jtmMsiLinkTemplate = require('../../../../config_public.json').frontend.jtmMsiLinkTemplate;

export default class JtmLinkView extends JetView{
    config(){       
        const status_form = {
            view:"form", 
            id:'jtm_form',
            width:650, borderless:true, margin:10,
            rows:[]
        };

        return {
            id:'app',
            cols:[{}, {
                rows:[                 
                        {},
                        status_form,
                        {}
                    ]},
                {}]
        };
    }

    urlChange(view){
        this.token = this.getParam("token");       
        if (this.token){
            this.refreshStatus();            
        }
        else{
            control.setValue('Invalid url parameters!'); 
        }
    }

    refreshStatus(){
        var form = this;
        ProgressHelper.showProgress($$("app"));
            var promise = JTM.validate(this.token);
            promise.then(function(response)
            {               
                var result = response.json();
                if(result !== null && result.status==='ok')
                {
                    //Has response
                    form.refreshScreenData(result);
                }
                else{
                    //Error during response
                    form.refreshScreenData(null);
                }
            });

            promise.fail(function(){
                form.refreshScreenData(null);
            });

            promise.finally(function(){
                ProgressHelper.hideProgress($$("app")); 
            });
    }

    refreshScreenData(result){
        var form_rows = [];
        var index = 0;

        if(result && result.device){

            var details = result ? JSON.parse(JSON.stringify(result.device.details)):null;

            form_rows[index++] = { view:"label",id:"status",label:`Status: ${result.device.isOnline ? "ONLINE":"OFFLINE"}`};
            form_rows[index++] = { view:"label",id:"serialNumber",label:`Serial number: ${result.device.serialNumber}`};
            form_rows[index++] = { view:"label",id:"connection",label:`Connection: ${details ? details.conn_no:""} / ${details ? details.conn_rat:""} / ${details ? details.conn_signal:""}`};
            form_rows[index++] = { view:"label",id:"voltage",label:`Supply voltage: ${details ? details.sup_volt:""}`};
            form_rows[index++] = { view:"label",id:"temp",label:`Temperature: ${details ? details.temp1:""}`};
            form_rows[index++] = { view:"label",id:"gps",label:`GNSS: <a href='https://www.google.com/maps/search/?api=1&query=${details.gnss_pos}' target='_blank'>${details.gnss_pos}</a>`};
            
            var jtmLink = jtmLinkTemplate.replaceAll("<token>",this.token);    
            form_rows[index++] = { view:"label", id:"jtm_link", label:jtmLink };
            form_rows[index++] = { view:"label", id:"download_link", label:jtmMsiLinkTemplate }
        }
        else {
            form_rows[index++] = { view:"label",label:"Invalid token!",align:"center"};
        }

        webix.ui({
            view:"form", 
            id:'jtm_form',
            width:650, borderless:true, margin:10,
            rows:form_rows
        }, $$('jtm_form'));

        //Call JTM Link immedietly
        var jtmHref = document.getElementById('jtmLink');
        if(jtmHref){
            jtmHref.click();
        }
    }
}