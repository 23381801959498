function hideData(value, config){
    let replacement = "";
    if (value){
      value = value+"";
      for (var i = 0; i < value.length; i++) {
        replacement += "*"
      }
    }
    return `<span>${replacement}</span>`;
  }

  
module.exports = {hideData};