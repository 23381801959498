//import "../styles/tokenmanager.css";
import {JetView, plugins} from "webix-jet";
const validator = require('validator');
const Helper = require('../../helpers/deviceToken');
var expirationOptions = [{id:-1, value:'Inactive'},{id:1, value:'1 day'},{id:7, value:'7 days'},{id:30, value:'30 days'},{id:90, value:'90 days'},{id:365, value:'365 days'},{id:9999, value:'Unlimited'}];

var tokenmanager_editor;
var tokenmanager_editor_form;
var parentDatatable;
var selectedItemId;

export default class EditorView extends JetView {
    config()
    {
        return {
			view:"popup",
			id: "editor",
			position:"center",
			body:{
			  view:"form", 
			  id: "editor-form",
			  elements:[
				//labels for non-editable fields
				{label: "Add/Edit Token ", view:"label", align:"center"},
				{label: "Email", view:"text", width:220, name:"email"},
				{label: "TAG 1",view:"text", width:220, name:"tag1"},
				{label: "TAG 2", view:"text", width:220, name:"tag2"},				
				{label: "Expiration", id:"expiration", view:"combo", width:220, name:"expiration",options:Helper.expirationOptions},
				{
				  cols: [
					{
					  view:"button", value:"Cancel", click: function() {
						tokenmanager_editor_form.clear();
						tokenmanager_editor.hide();
					  }
					},
					{
					  view:"button", value:"Submit", css: "webix_primary", click: this.editEnd
					}
				  ]
				}
			  ],
			}
		  }
	}

	init(view){
		tokenmanager_editor = $$("editor");
		tokenmanager_editor_form = $$("editor-form");
	}
	
	showWindow(datatable,id){
		parentDatatable = datatable;
		selectedItemId = id;

		if(selectedItemId){
			//Edit Item
			const vals = parentDatatable.getItem(selectedItemId);
			tokenmanager_editor_form.setValues(vals);
			$$("expiration").setValue(vals.selectedExpirationId);
			//Set new config with placeholder
			//var placeholderText = Helper.convertTokenExpirationToString(vals.expiration);
			//webix.ui({label: "Expiration", id:"expiration", view:"combo", width:220, name:"expiration",options:Helper.expirationOptions,placeholder:placeholderText}, $$('expiration'));
			
			
		}
		else{
			//New Item
			//webix.ui({label: "Expiration", id:"expiration", view:"combo", width:220, name:"expiration",options:Helper.expirationOptions}, $$('expiration'));
		}

		this.getRoot().show();
	}

	editEnd() {
		const vals = tokenmanager_editor_form.getValues();
		const hasEmailProp = vals.hasOwnProperty('email');

		//New item and email is empty or Update item and email is empty
		if((!selectedItemId && !vals.email) || (selectedItemId && hasEmailProp && vals.email.length==0))
		{
			webix.message({
				text:"Email is empty!",
				type:"error"
			});
			return;
		}

		if(vals.email && !validator.isEmail(vals.email)){
			webix.message({
				text:"Email is not valid!",
				type:"error"
			});
			return;
		}

		if(!selectedItemId && !vals.expiration){
			webix.message({
				text:"Expiration is empty!",
				type:"error"
			});
			return;
		}
			
		if(tokenmanager_editor_form.isDirty()){	
						
			if(vals.expiration){
				//Save selectedId
				vals.selectedExpirationId=vals.expiration;

				var nextExpiration = Helper.convertSelectedExpirationToMoment(vals.expiration);
				vals.expiration = nextExpiration.toJSON();
			}

			if(selectedItemId){				
				parentDatatable.updateItem(selectedItemId, vals);
			}
			else{
				//Add new row
				var newValues = {
					id : -1,
					serialNumber : null,
					token : null,
					isOnline : false,
					tag1 : vals.tag1 ? vals.tag1 : null,
					tag2 : vals.tag2 ? vals.tag2 : null,
					email : vals.email,
					selectedExpirationId:vals.selectedExpirationId,
					expiration : vals.expiration,
					rx : 0,
					tx : 0,
					lastLogin : null
				};

				parentDatatable.add(newValues,0);					
			}		
		}

			tokenmanager_editor_form.clear();
			tokenmanager_editor.hide();			
			selectedItemId = null;
		  
		}
	}