const locale = {
    dev_type : 'Device',
    dev_sn : 'SerialNumber',
    imei : 'IMEI',
    iccid : 'ICCID',
    msisdn : 'MSISDN',
    conn_no : 'Network Operator',
    conn_rat : 'Current RAT',
    conn_signal : 'Signal',
    conn_trafic : 'Trafix',
    temp1 : 'Temperature',
    sup_volt : 'Supply Voltage',
    uptime : 'Uptime',
    date : 'Device',
    work_hour : 'Work hour',
    gnss_pos : 'Lattitude, Longitude',
    haccuracy : 'Horizontal accuracy',
    altitude : 'Altitude',
    vaccuracy : 'Vertical accuracy',
    hw_ver : 'Hardware version',
    jtm_ver : 'JTM version',
    legato_ver : 'Legato version',
    linux_ver : 'Linux version',
    modem_ver : 'Modem version',
    cop_ver : 'COP version',
    ecm0_ip : 'USB Eth IP(ecm0)',
    eth0_ip : 'Ethernet0 IP',
    uap0_ip : 'Wifi Accesspoint IP',
    acp_ssid : 'Wifi SSID',
    otg_dir : 'OTG direction',
    e : 'Error'
};

module.exports = locale;