import {JetView, plugins} from "webix-jet";
import Header from "./menu/top";
import SideMenu from "./menu/left";

export default class IndexView extends JetView{
	config(){

		var ui = {
			rows:[
                Header,                
				{
                    cols: [SideMenu,{ $subview: true }],
				}
			]
		};

		return ui;
    }
    
	ready(){
		var user = this.app.getService("user").getUser();
		$$("left:sidebar").select(`pages.${user.isUser?"userdevice":"overview"}`);   
	}	
}