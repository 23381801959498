const linkServer = require('../../../config_public.json').linkServer;

function widiin_admin_backend_version(){
	return webix.ajax().get("api/version/adminServer");
}

function widiin_link_server_version(){
	return webix.ajax().get("api/version/linkServer");
}

module.exports = {widiin_admin_backend_version,widiin_link_server_version};