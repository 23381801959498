function details(serialNumber){
	return webix.ajax().get(`api/device/device?serialNumber=${serialNumber}`);
}

function status(serialNumber){
	return webix.ajax().get(`api/device/status?serialNumber=${serialNumber}`);
}

function setDeviceDescription(serialNumber,description){
	return webix.ajax().post("api/device/setDeviceDescription", {
		serialNumber,description
	});
}


module.exports = {details,status,setDeviceDescription};