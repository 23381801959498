function status(){
	return webix.ajax().post("api/login/status")
		.then(function(response){
			var respUser = response.json();
			if(!respUser){
				webix.storage.session.clear();
			}
			return respUser;
		});
}

function login(user, pass){
	//Clear session
	webix.storage.session.clear();

	return webix.ajax().post("api/login", {
		user, pass
	}).then(function(response) {		
		var respUser = response.json();
		if(respUser)
		{
			webix.storage.session.put('serialNumber', respUser.isUser ? '':respUser.name);
		}
		return respUser;
	});
}

function logout(){
	return webix.ajax().post("api/logout")
		.then(function(response) {
			webix.storage.session.clear();
			return response.json();			
		});
}

export default {
	status, login, logout
}
