import {JetView} from "webix-jet";
const forgotpassword = require('../../models/forgotpassword');

export default class ResetPasswordView extends JetView{
    
    config(){
        const reset_form = {
            view:"form",
            id:"resetpassword",
            width:450, borderless:false, margin:10,
            rows:[
                { view:"label", label:"Token:"},
                { view:"label", id:"token", name:"token"},
                { view:"label", label:"New password:"},
                { view:"text", type:"password", name:"new_password", labelPosition:"top" },    
                { view:"label", label:"Confirm new password:"},
                { view:"text", type:"password", name:"confirm_password", labelPosition:"top" },  
                { view:"button", value:"Reset password", click:() => this.resetpassword(), hotkey:"enter" }
            ],
            rules:{
                new_password:webix.rules.isNotEmpty,
                confirm_password:webix.rules.isNotEmpty
            }
        };

        return {
            cols:[{}, {
                localId:"login:top", rows:[
                {}, 
                reset_form,
                {}
            ]}, {}]
        };
    }

    urlChange(view){
        const token = this.getParam("token");
        if (token){
            $$("token").setValue(token);
        }
            
    }

    init(view){
        view.$view.querySelector("input").focus();
    }

    resetpassword(){
        const app = this.app;
        const form = this.$$("resetpassword");
        if (form.validate()){
            const data = form.getValues();
            
              if(data.new_password !== data.confirm_password){
                webix.message({
                    text:"New password and Confirm password is not equal!",
                    type:"error"
                });
                return;
              }              
              
            forgotpassword.resetpassword(data.token,data.new_password,data.confirm_password).then(function(response)
            {    
                var result = response.json();
                if(result.status !== 'ok')
                {
                    webix.message({
                        text:result.message,
                        type:"error"
                    });
                }
                else
                {
                    webix.alert(result.message).then(function(result)
                    {                        
                        app.show("/login");
                    });                    
                }
            });
        }        
    }
}
