function update_device(serialNumber,new_email,new_password){
	return webix.ajax().post("api/profile/update_device", {
		serialNumber,new_email,new_password
	});
}

function device_status(serialNumber){
	return webix.ajax().get(`api/profile/device_status?serialNumber=${serialNumber}`);
}

function user_status(){
	return webix.ajax().get("api/profile/status");
}

function validate(token){
	return webix.ajax().post("api/profile/validate", {
		token
	});
}

function setUserPassword(password,new_password,confirm_password){
	return webix.ajax().post("api/profile/setUserPassword", {
		password,new_password,confirm_password
	});
}

function setUserCompany(company){
	return webix.ajax().post("api/profile/setUserCompany", {
		company
	});
}

function createUserApiToken(user){
	return webix.ajax().post("api/profile/createUserApiToken", {
		user
	});
}


function setDeviceRootPublicKey(serialNumber,public_key){
	return webix.ajax().post("api/profile/setDeviceRootPublicKey", {
		serialNumber,public_key
	});
}

function setDeviceDescription(description){
	return webix.ajax().post("api/profile/setDeviceDescription", {
		description
	});
}

module.exports = {device_status,user_status,update_device,validate,setUserPassword,setUserCompany,setDeviceRootPublicKey,setDeviceDescription,createUserApiToken};