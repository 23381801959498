import {JetView, plugins} from "webix-jet";

export default class TopMenuView extends JetView{
	config(){	

		var user = this.app.getService("user").getUser();
		var userId = user.isUser ? "profile.profile_user":"profile.profile_device";

		return {
      		view:"popup",
      		body:{
        		view:"list",
				id:"topmenu:list",
        		width:175,
					data:[
						{ value:`${user.isUser ? 'User' : 'Device'} settings`,	id:userId, icon:"mdi mdi-cog" },
						{ value:"Logout",	id:"logout",  icon:"mdi mdi-logout" }
					],
					on:{
						onItemClick:id => {
							this.handleMenuItem(id);
						}
					},
					autoheight:true
      			}
    };		
  }

  init(){
	this.user = this.app.getService("user").getUser();	
  }

   showPopup(pos){
	   if(this.getRoot().isVisible()){
			this.getRoot().hide();
	   }
	   else{
			this.getRoot().show(pos);
	   }
	}
	
	handleMenuItem(id)
	{
		this.getRoot().hide();

		if(id=="logout")
		{
			this.show("/logout");
			return;
		}

		var link = "/index/" + id;
          this.show(link);
          
		  var selectedItem = $$("topmenu:list").getItem(id);
          var headerName = $$("top:headerName");
		  var serialNumber = webix.storage.session.get('serialNumber');
          headerName.define("label",`Widiin - ${selectedItem.value} - ${serialNumber}`)
          headerName.refresh();

		  //Unselect other menus
		  var sideMenu = $$("left:sidebar");
		  var selectedItem = sideMenu.getSelectedItem();
		  sideMenu.unselect(selectedItem.id); 
	}
}