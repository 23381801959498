const moment = require('moment');

const expirationOptions = [{id:-1, value:'Inactive'},{id:1, value:'1 day'},{id:7, value:'7 days'},{id:30, value:'30 days'},{id:90, value:'90 days'},{id:365, value:'365 days'},{id:9999, value:'Unlimited'}];

function convertTokenExpirationToString(expiration)
{
    var result = '';
    var expDate = moment.utc(expiration);
    if(expDate.year()==9999) {
        result='Unlimited';
    } 
    else if(expDate < moment.utc().startOf('day')) {
        result='Expired';
    } 
    else{
        result = expDate.local().format('L');
    }

    return result;
}

function convertSelectedExpirationToMoment(expiration)
{
    var nextExpiration = moment.utc();
				
	if(expiration == 9999){
		nextExpiration = moment.utc('9999-12-31');
	}
	else if(expiration==-1) {
		nextExpiration.add(-1,'days');
	} else {
		nextExpiration.add(expiration,'days');
    }
    
	return nextExpiration.startOf('day');
}

module.exports = {convertTokenExpirationToString,convertSelectedExpirationToMoment,expirationOptions};