import "./styles/app.css";
import {JetApp, EmptyRouter, HashRouter,plugins } from "webix-jet";
import session from "models/session";
const sessionCheckTimeoutInSec = require('../../config_public.json').frontend.sessionCheckTimeoutInSec;
const sortPrototype = require('./prototype/sort/custom')
const registerType = require('./controls/property_sheet/registerType')

export default class WidiinAdminApp extends JetApp{
	constructor(config){
		const defaults = {
			id 		: APPNAME,
			version : VERSION,
			router 	: BUILD_AS_MODULE ? EmptyRouter : HashRouter,
			routerPrefix:"",
			debug 	: !PRODUCTION,
			start 	: "/index"
		};

		config = {
			name: "Widiin"
		  };

		super({ ...defaults, ...config });

		this.use( plugins.User, 
			{ 
				model : session,
				ping : sessionCheckTimeoutInSec * 1000,
				public: function(path)
				{
					if(path.indexOf("jtmlink") > -1 || path.indexOf("resetpassword") > -1 || path.indexOf("validateprofile") > -1)
						{
							return true;
						}
					return false;
				}
			});
		on:{
			onInit:this.onInit();
		}
	}


	onInit(){
		// custom sorting type for /* view name here */
		sortPrototype.addCustomSorting();
		registerType.setCustomPassword();
	}
}

if (!BUILD_AS_MODULE){
	webix.ready(() => new WidiinAdminApp().render() );
}