import {JetView} from "webix-jet";
const profile = require('../../models/profile');

export default class ValidateProfileView extends JetView{
    config(){       
        const login_form = {
            view:"form", 
            width:400, borderless:true, margin:10,
            rows:[
                { view:"label",id:"result"}
            ]
        };

        return {
            cols:[{}, {
                rows:[                 
                {},
                login_form,{}
                            ]},
                {}]
        };
    }

    urlChange(view){
        const token = this.getParam("token");
        var control = this.$$("result");
        if (token){
            profile.validate(token).then(function(response)
            {               
                var result = response.json();
                if(result !== null)
                {
                    control.setValue(result.message);
                }
                else{
                    control.setValue('Email validation failed!');
                }
            });
        }
        else{
            control.setValue('Invalid token!'); 
        }
    }
}