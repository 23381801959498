function requesttoken(username){
    return 	webix.ajax().post("api/forgotpassword/requesttoken", {
        username
    });
}

function resetpassword(token,new_password,confirm_password){
	return webix.ajax().post("api/forgotpassword/resetpassword", {
        token,new_password,confirm_password
    });
}

module.exports = {requesttoken,resetpassword};