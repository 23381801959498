import "../../styles/common.css";
import {JetView, plugins} from "webix-jet";
const ProgressHelper = require('../../helpers/progress');
const Helper = require('../../helpers/deviceToken');
const moment = require('moment');
const jtmLinkTemplate = require('../../../../config_public.json').frontend.jtmLinkTemplate;

export default class MyTokenView extends JetView {
    config()
    {
		var user =this.app.getService("user").getUser();

        var datatable = {
			id:"tokensDatatable",
			view:"datatable", 
			columns:[
				{ id:"serialNumber", width:150, header:[{text:"SerialNumber", content:"excelFilter", mode:"text"}], sort:"string"},
				{ id:"isOnline",    header:[{text:"Status", content:"excelFilter", mode:"number"}],template:this.formatStatus, sort:"boolean"},
				{ id:"signal",width:75,    header:"Signal",template:this.formatSignal},
				{ id:"description",  width:200, header:[{text:"Description", content:"excelFilter", mode:"text"}], sort:"string"},
				{ id:"gps",  width:200,   header:"GPS",template:this.formatGps},
				{ id:"token", width:300,  header:[{text:"Token", content:"excelFilter", mode:"text"}],template:this.formatToken, sort:"string"},
				{ id:"tag1", width:220,  header:[{text:"TAG1", content:"excelFilter", mode:"text"}], sort:"string"},
				{ id:"tag2", width:220, editor:"text",  header:[{text:"TAG2", content:"excelFilter", mode:"text"}], sort:"string"},
				{ id:"expiration",   header:"Expiration",template:this.formatExpiration, sort:"moment"},				
				{ id:"rx",	header:[{text:"Rx", content:"excelFilter", mode:"number"}],template:this.formatRx,sort:"number"},
				{ id:"tx",  header:[{text:"Tx", content:"excelFilter", mode:"number"}],template:this.formatTx,sort:"number"},
				{ id:"lastLogin", width:150,   header:"LastLogin",template:this.formatDate,sort:"moment"}				
			],
			editable: true,
			autoConfig:true ,
			url:`api/devicetoken/listTokensByEmail?email=${user.name}`,				
			save:{
				"update":`api/devicetoken/updateTag2`
			},
			on:{	
				"onAfterLoad":this.afterLoad,				                  
				onBeforeEditStart:function(id){				
					return id.column === "tag2";				
			  }
			} 
		};

        return {
			id:'app',			
            cols:[
				{
              rows:[		
				datatable
				]
			  }
			],
        }
	}	

    init(view){
		this.tokensDatatable = $$("tokensDatatable");
		//Not working the base initialization if insert,update,delete url used. Only url works correctly
		var dataProcessor = webix.dp(this.tokensDatatable);
		dataProcessor.config.updateFromResponse = true;
		dataProcessor.attachEvent('onAfterSaveError', function(id, status, response, details){			
			//Delete new row if error occured
			if(status=='update'){
				webix.message({
					text:"Updating token failed!",
					type:"error"
				});
			}
		});
	}   

	formatToken(obj){
		var jtmLink = jtmLinkTemplate.replaceAll('<token>',obj.token?obj.token:''); 
		return jtmLink;
	}

	formatStatus(obj){
		var result = 'Offline';
		if(obj.isOnline){
			result = 'Online';
		}
		return result;
	}

	formatRx(obj){		
		return (obj.rx ? obj.rx:0)+' MB';
	}

	formatTx(obj){
		return (obj.tx ? obj.tx:0)+' MB';
	}

	formatExpiration(obj){
		var result = Helper.convertTokenExpirationToString(obj.expiration);

		return result;
	}

	formatDate(obj){
		var result = '';
		if(obj.lastLogin){
			result = moment.utc(obj.lastLogin).local().format('L LT');
		}
		return result;
	}	

	formatSignal(obj){
		var details = obj ? JSON.parse(obj.details):null;
		if(details){			
			var numbers = details.conn_signal ? details.conn_signal.match(/\d+/g).map(Number) : 0;
			if(numbers.length > 0){
				switch(numbers[0]){
					case 0:
						return `<span class='mdi mdi-wifi-strength-off-outline'/>`;
					case 1:
					case 2:
					case 3:
						return `<span class='mdi mdi-wifi-strength-${numbers[0]}'/>`;
					default:
						return `<span class='mdi mdi-wifi-strength-4'/>`;
				}
			}
			return `<span class='mdi mdi-wifi-strength-off-outline'/>`;
		}
		return  `<span class='mdi mdi-wifi-strength-off-outline'/>`;
	}

	formatGps(obj){
		var details = obj ? JSON.parse(obj.details):null;
		if(details && details.gnss_pos){
			return `<a href='https://www.google.com/maps/search/?api=1&query=${details.gnss_pos}' target='_blank'>${details.gnss_pos}</a>`;
		}
		return '';
	}

	afterLoad(){		
		this.eachRow(function(row){ 
			const record = this.getItem(row);
			
			if(record.isOnline===true || record.isOnline===1){		
				this.addRowCss(row,'online');
			}
		});

		ProgressHelper.hideProgress($$("app")); 
	}
}
