import {JetView, plugins} from "webix-jet";
import Version from "../../models/version";
const frontendVersion = require('../../../properties.json').version;
const jtmLinkVersion = require('../../../../config_public.json').frontend.jtmMsiVersion;

export default class VersionView extends JetView {
    config()
    {
      var user = this.app.getService("user").getUser();
      
      var index = 0;      
      var uiRows = [];

        uiRows[index++]  = {
          view:"property",  id:"main_property",nameWidth:225,
          elements:[
            { label:"Version information", type:"label" },
            { label:"Widiin Admin Server Backend", type:"text", id:"widiin_admin_backend", value:"unknown" },
            { label:"Widiin Admin Server Frontend", type:"text", id:"widiin_admin_frontend", value:"unknown" },
            { label:"Widiin Link Server", type:"text", id:"widiin_link", value:"unknown" },
            { label:"JTM Link", type:"text", id:"jtmlink", value:"unknown" },               
          ],
          on:{
            'onBeforeEditStart':function(id){   
                return false;
            }
          }
        };

        return {
            id:'app',
            rows:uiRows
          }
    } 
    
    init(){
        this.version_sheet = $$("main_property");
        this.loadWidinnAdminServerFrontend();
        this.loadWidinnAdminServerBackend();
        this.loadWidinnLinkServer();
        this.loadJTMLink();
    }

    loadWidinnAdminServerBackend()
    {
        let sheet = this.version_sheet;
        Version.widiin_admin_backend_version().then(function(response)
        {
            if(response != null){
                sheet.setValues({  
                    widiin_admin_backend:response.json()  
                  },true);
            };
        });
    }

    loadWidinnAdminServerFrontend(){
        this.version_sheet.setValues({  
            widiin_admin_frontend:frontendVersion  
          },true);
    }

    loadWidinnLinkServer(){
        let sheet = this.version_sheet;
        Version.widiin_link_server_version().then(function(response)
        {
            if(response != null){
                sheet.setValues({  
                    widiin_link:response.json()  
                  },true);
            };
        });
        
    }

    loadJTMLink(){
        this.version_sheet.setValues({  
            jtmlink:jtmLinkVersion  
          },true);
    }
}