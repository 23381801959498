import {JetView, plugins} from "webix-jet";
const widiin_help_url = require('../../../../config_public.json').frontend.widiin_help_url;

export default class WidiinHelpView extends JetView {
    config()
    {
        var pdfViewViewver = { 
            view:"pdfviewer", 
            id:"pdf",
            toolbar:"toolbar", 
            url:`binary->${widiin_help_url}`
          };

        return {
			type:'space',			
            rows:[	
				{ view:"pdfbar", id:"toolbar" },
                pdfViewViewver
			]
        }
	}
}
