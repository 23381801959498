const moment = require('moment');

function addCustomSorting(){
    webix.DataStore.prototype.sorting.as.boolean = function(a,b){
        return a > b ? 1 : -1;
    }

    webix.DataStore.prototype.sorting.as.moment = function(a,b){
        if(!a && !b){
            return -1;
        }

        if(!a && b){
            return -1;
        }

        if(a && !b){
            return 1;
        }        
        
        var expA = moment.utc(a);
        var expB = moment.utc(b);
        
        return expA.isAfter(expB) ? 1 : -1;
    }

    webix.DataStore.prototype.sorting.as.number = function(a,b){
        return Number(a) > Number(b) ? 1 : -1;
    }
}

module.exports = {addCustomSorting};