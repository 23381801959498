function showProgress(view,config)
{
    view.disable();
    webix.extend(view, webix.ProgressBar);
    if(config){
        view.showProgress(config);
    }
    else{    
        view.showProgress();
    }
}

function hideProgress(view)
{
    view.enable();
    if(view.hasOwnProperty('hideProgress')){
        view.hideProgress();
    }
}

module.exports = {showProgress,hideProgress};